<template>
  <div class="programatic-campaign-navigation">
    <ul class="font-size-xSmall">
      <li
        id="campaign-step"
        class="mb-3"
        :class="campaignClasses"
      >
        <span class="square">
          {{ isCampaignPaused ? '!' : '1' }}
        </span>
        <span class="text">{{ $t('campaign') }}</span>
        <b-tooltip
          target="campaign-step"
          :title="$t('campaign_paused')"
          container="body"
          v-if="isCampaignPaused"
        ></b-tooltip>
      </li>
      <li
        id="pixel-step"
        class="mb-3"
        :class="trackingClasses"
      >
        <span class="square">
          {{ isCampaignPaused ? '!' : '2' }}
        </span>
        <span class="text">{{ $t('pixels') }}</span>
        <b-tooltip
          target="pixel-step"
          :title="$t('campaign_paused')"
          container="body"
          v-if="isCampaignPaused"
        ></b-tooltip>
      </li>
      <li
        id="package-step"
        class="mb-3"
        :class="packageClasses"
      >
        <span class="square">
          {{ isPackagePaused ? '!' : '3' }}
        </span>
        <span class="text">{{ $t('campaign_package') }}</span>
        <b-tooltip
          target="package-step"
          :title="$t('campaign_group_paused')"
          container="body"
          v-if="isPackagePaused"
        ></b-tooltip>
      </li>
      <li
        id="line-step"
        class=""
        :class="lineClasses"
      >
        <span class="square">
          {{ isLinePaused ? '!' : '4' }}
        </span>
        <span class="text">{{ $t('strategy') }}</span>
        <b-tooltip
          target="line-step"
          :title="$t('paused_strategy')"
          container="body"
          v-if="isLinePaused"
        ></b-tooltip>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ProgramaticCampaignNavigation',
  props: ['campaign', 'innerStep', 'line', 'segmentation', 'step'],
  computed: {
    /**
     * Campaign step classes
     */
    campaignClasses () {
      const array = ['active']
      if (this.isCampaignPaused) array.push('is-paused')
      return array
    },
    /**
     * Is campaign paused
     */
    isCampaignPaused () {
      return this.campaign && this.campaign.status === 'PAUSED'
    },
    /**
     * Is line paused
     */
    isLinePaused () {
      return this.line && this.line.status === 'PAUSED'
    },
    /**
     * Is package is paused
     */
    isPackagePaused () {
      return this.segmentation && this.segmentation.status === 'PAUSED'
    },
    /**
     * Line step classes
     */
    lineClasses () {
      const array = []
      if (this.step === 'line') array.push('active')
      if (this.isLinePaused) array.push('is-paused')
      return array
    },
    /**
     * Package step classes
     */
    packageClasses () {
      const array = []
      if (this.step !== 'campaign') array.push('active')
      if (this.isPackagePaused) array.push('is-paused')
      return array
    },
    /**
     * Tracking step classes
     */
    trackingClasses () {
      const array = []
      // Will always be active if is not campaign and init of first step
      if (this.step !== 'campaign' || (this.step === 'campaign' && this.innerStep !== 'campaign' && this.innerStep !== 'init')) array.push('active')
      return array
    }
  }
}
</script>

<style lang="scss" scoped>
  .programatic-campaign-navigation {
    ul {
      background: $color9;
      color: #fff;
      padding: 20px 10px;
      width: 80px;

      li {
        text-align: center;
        list-style: none;

        &.active {
          .square {
            background: $color1;
            color: $color9;
          }
        }

        &.is-paused {
          cursor: pointer;

          .square {
            background: $color4;
            color: #fff;
          }
        }

        .square {
          display: flex;
          width: 20px;
          height: 20px;
          align-items: center;
          justify-content: center;;
          background: #fff;
          margin: 0 auto;
          color: $color9;
        }
        .text {
          display: block;
        }
      }
    }
  }
</style>
