import axios from '@/plugins/axios'

/**
 * Activate campaign package
 */
const activateCampaignPackage = async (id) => {
  try {
    const response = await axios.post(`packages/${id}/activate`, {
      _method: 'PUT'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Create campaign package
 */
const createCampaignPackage = async (form) => {
  try {
    const response = await axios.post('packages', form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Pause campaign package
 */
const pauseCampaignPackage = async (id) => {
  try {
    const response = await axios.post(`packages/${id}/pause`, {
      _method: 'DELETE'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Retry creation on platform
 */
const retryCreationOnPlatform = async id => {
  try {
    const response = await axios.post(`packages/${id}/retry`, {
      _method: 'PUT'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update campaign package
 */
const updateCampaignPackage = async (id, form) => {
  try {
    form._method = 'PUT'
    const response = await axios.post(`packages/${id}`, form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  activateCampaignPackage,
  createCampaignPackage,
  pauseCampaignPackage,
  retryCreationOnPlatform,
  updateCampaignPackage
}
