<template>
  <b-modal
    id="cancel-campaign-upsert-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ $t('cancel') }}</span>
      </h2>
    </div>
    <div class="text-center mt-3">
      <div class="mb-4">
        <i class="fa fa-warning font-size-large"></i>
      </div>
      <p>{{ $t('are_you_sure_you_want_to_leave_this_page') }}</p>
      <p>{{ $t('all_changes_made_so_far_will_be_lost') }}</p>
      <Button
        type="button"
        class="btn btn-primary mt-4"
        @click="click"
      >
        {{ $t('cancel') }}
      </Button>
    </div>
  </b-modal>
</template>

<script>

import Button from '@/components/common/Button/Button'

export default {
  name: 'CancelCampaignCreationModal',
  props: ['campaign', 'line', 'segmentation', 'step'],
  components: {
    Button
  },
  methods: {
    /**
     * Click
     */
    async click () {
      this.$bvModal.hide('cancel-campaign-upsert-modal')
      if (this.step === 'campaign') {
        if (this.campaign) this.$router.push({ name: 'campaigns.show', params: { id: this.campaign.id } })
        else this.$router.push({ name: 'campaigns.index' })
      } else if (this.step === 'line') {
        this.$router.push({ name: 'lines.show', params: { cId: this.campaign.id, id: this.line.id } })
      } else if (this.step === 'package') {
        this.$router.push({ name: 'packages.show', params: { cId: this.campaign.id, id: this.segmentation.id } })
      }
    }
  }
}
</script>
